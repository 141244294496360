import React from 'react';
import banner from "../img/ferus.jpg";
import jose from "../img/jose.jpg";
import miguel from "../img/miguel.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="Presentación del programa Interreg Sudoe" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-success centrado" role="alert">
					<h5>ACTO DE PRESENTACIÓN DE FERUS</h5>
					<h6>Nuevo servicio técnico de Itagra</h6>
					<hr></hr>
					<h6><strong>Fecha:</strong> jueves 20 de abril de 2023</h6>
					<h6><strong>Lugar:</strong> Salón de actos de ETSIIAA, Edificio Verde. Campus de la Yutera en Palencia. Universidad de Valladolid.</h6>
					</div>
				</div>
			</div>
			<div className="card text-black bg-warning w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p>
        			</a>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda:</h5>
						<li><strong>11:00 h.</strong> Presentación institucional de FERUS a cargo de:
							<ul>
								<li><strong>Joaquín Navarro Hevia (director de la ETSIIAA).</strong> Dará la bienvenida a los asistentes en calidad de anfitrión y recordará que este año se celebra el 50 aniversario de la creación de la ETSIIAA en Palencia.</li>
								<li><strong>Asier Saiz Rojo (director del ITAGRA).</strong> Presentará ITAGRA y su vinculación con FERUS.</li>
								<li><strong>Juan José Luque Larena (codirector de FERUS).</strong> Presentará FERUS.</li>
							</ul>
							<p>El acto finalizará con el avance de las charlas que se impartirán a continuación, abriendo el turno de preguntas a los medios de comunicación.</p>
						</li>
						<li><strong>11:30 h. </strong> Café</li>
						<li><strong>12:00 h. </strong>Fernando Jubete Tazo (codirector de FERUS) presentará brevemente a los dos ponentes de reconocido prestigio invitados para la presentación. A continuación, exposiciones libres a cargo de los ponentes <strong>Miguel Delibes de Castro</strong> (doctor en Ciencias Biológicas) y <strong>José Jiménez García-Herrera</strong> (ingeniero de Montes y doctor en Ciencias Agrarias y Ambientales), seguido de una mesa redonda participativa entre los asistentes. Ambos autores incidirán en la importancia y necesidad de aplicar el método científico en la gestión de poblaciones de fauna silvestre.
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/649" width="95%" height="380px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
					</div>
				</div>
			</div>
			<hr></hr>
			<div className='container'>
			<div className='row'>
			<div class="card mb-3">
			<div class="row g-0">
				<div class="col-md-4">
				<img src={jose} class="img-fluid rounded-start" alt="..."/>
				</div>
				<div class="col-md-8">
				<div class="card-body">
				<h5 class="card-title">José Jiménez García-Herrera</h5>
							<h6 class="card-title">Dr. Ingeniero de Montes - IREC/CSIC</h6>
							<ul class="list-group list-group-flush">
								<li class="list-group-item">Cuerpo de Ingenieros de Montes del Estado desde 1985.</li>
								<li class="list-group-item">Director del Parque Nacional de Cabañeros, de 1988 a 2006.</li>
								<li class="list-group-item">Jefe de Área de Cooperación en Medio Ambiente en la Agencia Española de Cooperación Internacional (AECID), 2006-2008.</li>
								<li class="list-group-item">Director Adjunto y director del Organismo Autónomo Parques Nacionales, en el Ministerio de Medio Ambiente y Medio Rural y Marino, 2008 y 2009.</li>
								<li class="list-group-item">Director General de Medio Natural y Política Forestal, 2009-2011.</li>
								<li class="list-group-item">Investigador en el Consejo Superior de Investigaciones Científicas, desde 2014.</li>
							</ul>
				</div>
				</div>
			</div>
			</div>

			<div class="card mb-3">
			<div class="row g-0">
				<div class="col-md-4">
				<img src={miguel} class="img-fluid rounded-start" alt="..."/>
				</div>
				<div class="col-md-8">
				<div class="card-body">
				<h5 class="card-title">Miguel Delibes de Castro</h5>
							<h6 class="card-title">Dr. Ciencias Biológicas -EBD/CSIC</h6>
							<ul class="list-group list-group-flush">
								<li class="list-group-item">Profesor de Investigación del Consejo Superior de investigaciones Científicas.</li>
								<li class="list-group-item">Director de la Estación Biológica de Doñana (EBD-CSIC), desde 1988 a 1996.</li>
								<li class="list-group-item">Director del Grupo de Ecología de Carnívoros de la EBD-CSIC.</li>
								<li class="list-group-item">Miembro de la Unión Internacional para la Conservación de la Naturaleza (UICN), y asesor de la delegación española en la Cumbre de la Biodiversidad, Río de Janeiro, en 1992.</li>
								<li class="list-group-item">Fundador y presidente de la Sociedad Española de Estudio y Conservación de los Mamíferos (SECEM).</li>
								<li class="list-group-item">Presidente del Consejo de Participación del Espacio Natural de Doñana, desde 2013.</li>
								<li class="list-group-item">Miembro de la Real Academia de Ciencias Exactas, Físicas y Naturales, desde 2014.</li>
							</ul>
				</div>
				</div>
			</div>
			</div>

			</div>
			<hr></hr>
			<div className="row">
				<div className="col-12 info">
					Más información <i class="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp;
					<i class="fas fa-paper-plane"></i>&nbsp;info@itagra.com
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					<a href="https://www.itagra.com">Itagra CT</a>
				</div>
			</div>
		</div>
		</div>
)

export default Home